

// auth.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { JwtHelper } from 'angular2-jwt';
import { AuthHttp, AuthConfig } from 'angular2-jwt';
import { tokenNotExpired } from 'angular2-jwt';



@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {


  constructor(private router: Router,private service : UserService) {
  }
  jwtHelper: JwtHelper = new JwtHelper();
 
  useJwtHelper() {
    var token = localStorage.getItem('token');
   
    console.log(
      this.jwtHelper.decodeToken(token),
      this.jwtHelper.getTokenExpirationDate(token),
      this.jwtHelper.isTokenExpired(token)
    );
  }
  
  isActive(){
   
  }
 canActivate(
  
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('token') != null){
      debugger;
      let roles = next.data['permittedRoles'] as Array<"Admin">;
      if(roles){
        if(this.service.roleMatch(roles)) return true;
        else{
          this.router.navigate(['/login']);
          return false;
        }
      }
      return true;
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }

  }
}
