import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { UserService } from './shared/services/user.service';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Rouby';
  @ViewChild('ngxLoading', /* TODO: add static flag */ {}) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate', /* TODO: add static flag */ {}) customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = true;
  public primaryColour = PrimaryRed;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };
  constructor(private router: Router,private userService: UserService) { }
  // @HostListener('window:unload', [ '$event' ])
  // unloadHandler(event) {
  //   alert('call unload');
  // }

  // @HostListener('window:beforeunload', [ '$event' ])
  // beforeUnloadHander(event) {
  //   this.userService.logout();
  //   // this.router.navigate(['/login']); 

  // }
  //public loading = false;
  ngOnInit() {
    //this.primaryColour = PrimaryRed;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.loading=false
      this.loadingTemplate = null;
      window.scrollTo(0, 0);
    });
  }
}
