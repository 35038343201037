import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';

import { UserRegistration } from '../models/user.registration.interface';
import { ConfigService } from '../utils/config.service';

import {BaseService} from "./base.service";

import { Observable } from 'rxjs/Rx';
import { BehaviorSubject } from 'rxjs/Rx'; 

// Add the RxJS Observable operators we need in this app.
import '../../rxjs-operators';
import { catchError, map } from 'rxjs/operators';
import { UserRegistration2 } from '../models/userRegistration2-model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginModel } from 'src/app/pages/login/models/login-model';

@Injectable()

export class UserService extends BaseService {
  baseUrl = environment.DevApiUrl;
  //baseUrl: string = '';

  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();

  private loggedIn = false;

  constructor(private http: HttpClient) {
    super();
    this.loggedIn = !!localStorage.getItem('token');
    // ?? not sure if this the best way to broadcast the status but seems to resolve issue on page refresh where auth status is lost in
    // header component resulting in authed user nav links disappearing despite the fact user is still logged in
    this._authNavStatusSource.next(this.loggedIn);
    //this.baseUrl = configService.getApiURI();
  }

//       register(payload: UserRegistration): Observable<UserRegistration> {
//     //let body = JSON.stringify({ email, password, firstName, lastName,location });
//     let headers = new Headers({ 'Content-Type': 'application/json' });
//      let options = new RequestOptions({ headers: headers });

//      const httpOptions = {
//       headers: new HttpHeaders({
//         'Content-Type': 'application/json'
//       })
//     };
// debugger;
//    // return this.http.post(this.baseUrl + "/accounts", body, options)
//    //   .map(res => true)
//    //    .catch(this.handleError);
//    return this.http
//    .post<UserRegistration>(`${this.baseUrl}/Accounts`, payload,httpOptions)
//    .pipe(catchError((error: any) => Observable.throw(error.json())));

//   //  return this.http.post(this.baseUrl +"/accounts", body, httpOptions )
//   //  .map(res =>res.json())
//   //   .catch(this.handleError);
//   } 
  
register(user: UserRegistration): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Accounts`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  // login(user: LoginModel): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };
  //   return this.http
  //     .post(`${this.baseUrl}/Auth`, user, httpOptions)
  //     .pipe(map=>{

  //     }
        
  //       catchError((error: any) => Observable.throw(error.json())));
  // }

  login(user: LoginModel) {
    debugger;
    const httpOptions = {
           headers: new HttpHeaders({
            'Content-Type': 'application/json'
           })
        };
    return this.http.post<any>(`${this.baseUrl}/account/token`, user,httpOptions)
        .pipe(map(user => {
       
            if (user) {
              //var myUser = JSON.parse(user);
              localStorage.setItem('token', user.token);
              this.loggedIn = true;
              this._authNavStatusSource.next(true);
              localStorage.setItem('currentUser', JSON.stringify(user));
              
              return true;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('currentUser', JSON.stringify(user));
               

            }
            console.log(this.loggedIn);

//console.log(user);
            return true;
        })
        );
}




  


  //  login(user: UserRegistration) {
  //   let headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };
  //   return this.http
  //   .post(`${this.baseUrl}/Auth`, user, httpOptions)
  //     .map(res => {
  //       localStorage.setItem('auth_token', res.auth_token);
  //       this.loggedIn = true;
  //       this._authNavStatusSource.next(true);
  //       return true;
  //     })
  //     .catch(this.handleError);
  // }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    
    this.loggedIn = false;
    this._authNavStatusSource.next(false);
    
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  facebookLogin(accessToken:string) {
    const httpOptions = {
           headers: new HttpHeaders({
            'Content-Type': 'application/json'
           })
        };
        let body = JSON.stringify({ accessToken });  
    return this.http.post<any>(`${this.baseUrl}/externalauth/facebook`, body,httpOptions)
        .pipe(map(user => {
          debugger;
            if (user) {
              var myUser = JSON.parse(user);
              localStorage.setItem('auth_token', myUser.auth_token);
              this.loggedIn = true;
              this._authNavStatusSource.next(true);
              localStorage.setItem('currentUser', JSON.stringify(user));
              //console.log(user);
              console.log(this.loggedIn);
              return true;
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              //localStorage.setItem('currentUser', JSON.stringify(user));
               

            }
            console.log(this.loggedIn);

//console.log(user);
            return true;
        })
        );
}


  //  facebookLogin(accessToken:string) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };
  //    let headers = new Headers();
  //    headers.append('Content-Type', 'application/json');
  //    let body = JSON.stringify({ accessToken });  
  //    return this.http
  //      .post(
  //      this.baseUrl + '/externalauth/facebook', body, httpOptions)
  //      .map(res => res.json())
  //      .map(res => {
  //        localStorage.setItem('auth_token', res.auth_token);
  //        this.loggedIn = true;
  //        this._authNavStatusSource.next(true);
  //        return true;
  //      })
  //      .catch(this.handleError);
  //  }


  roleMatch(allowedRoles): boolean {
    debugger;
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    allowedRoles.forEach(element => {
      if (userRole == element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  CheckroleMatch(allowedRoles): boolean {
    debugger;
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    debugger;
    var userRole = payLoad.role;
    if(userRole==allowedRoles){
      return true;
    }
    else
    return false
  }

  GetUserFullNameName(): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    return payLoad.Email;
  
  }
}

