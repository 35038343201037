import { RegistrationModule } from './pages/registration/registration.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import {DoctorLayoutComponent} from './layout/doctor-layout/doctor-layout.component';
import { HomeComponent } from './layout/home/home.component';
const routes: Routes = [
  {
    path: '', pathMatch: 'prefix',
    component: AdminComponent,
   // component: AdminComponent,
   data: {
    // permittedRoles:['Admin','Student','Techer']
  },
    children: [
      {
        path: '',
        redirectTo: 'dashboard/default',
        pathMatch: 'full'
      },
      {
        path: 'Home',
        redirectTo: 'dashboard/default',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
      },

      {
        path: 'clinics',
        loadChildren: './pages/clinics/clinics.module#ClinicsModule'
      },
      {
        path: 'pharmaceutical',
        loadChildren: './pages/pharmaceutical/pharmaceutical.module#PharmaceuticalModule'
      },
      {
        path: 'companies',
        loadChildren: './pages/companies/companies.module#CompniesModule'
      },
      {
        path: 'master-data',
        loadChildren: './pages/master-data/master-data.module#MasterDataModule'
      },
      {
        path: 'user',
        loadChildren: './pages/user/user.module#UserModule'
      },
      {
        path: 'setting',
        loadChildren: './pages/setting/setting.module#SettingModule'
      },
      {
        path: 'accounts',
        loadChildren: './pages/Accounts/accounts.module#AccountsModule'
      },
      {
        path: 'hr',
        loadChildren: './pages/hr/hr.module#HRModule'
      },
      {
        path: 'users-for-admin',
        loadChildren: './pages/companies/users-for-admin-company/users-for-admin-company.module#UsersForAdminCompanyModule'
      },
      
    ]
  },
  {
    path: '', pathMatch: 'prefix',
    component: DoctorLayoutComponent,
   // component: AdminComponent,
   data: {
    // permittedRoles:['Admin','Student','Techer']
  },
    children: [
      {
        path: '',
        redirectTo: 'doctor-dashbord',
        pathMatch: 'full'
      },
      {
        path: 'Home',
        redirectTo: 'doctor-dashbord',
        pathMatch: 'full'
      },
      {
        path: 'doctor-dashbord',
        loadChildren: './pages/dashboard/doctor-dashboard/doctor-dashboard.module#DoctorDashboardModule'
      },
    ]
  },
  {
    path: '',
    component: DoctorLayoutComponent,
    children: [
      // {
      //   path: 'clinics',
      //   loadChildren: './pages/clinics/clinics.module#ClinicsModule'
      // }
      // ,
      {
        path: 'registration',
        loadChildren: './pages/registration/registration.module#RegistrationModule'
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginModule'
      },
      {
        path: 'registration',
        loadChildren: './pages/registration/registration.module#RegistrationModule'
      },
      {
        path: 'registr-company',
        loadChildren: './pages/companies/register-company/register-company.module#RegisterCompanyModule'
      },
      
    ]
  },
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'index',
        loadChildren: './pages/index/index.module#IndexModule'
      }
      ,
    ]
  },

  // {
  //   path: '',
  //   component: AuthComponent,
  //   children: [
  //     {
  //       path: 'auth',
  //       loadChildren: './pages/auth/auth.module#AuthModule'
  //     },

  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
