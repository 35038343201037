
import {Injectable} from '@angular/core';
import { AdminGuard } from 'src/app/auth/admin.guard';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
 
  {
    label: 'الرئسية',
    main: [
      {
        state: 'Home',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },

  
  {
    label: 'البيانات الرئسية',
    main: [
      {
        state: 'master-data',
        short_label: 'm',
        name: 'البيانات الرئسية',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          // {
          //   state: 'departments',
          //   name: 'الإدارات'
          // },
          // {
          //   state: 'complex-uses',
          //   name: ' مجمع الاستخدامات '
          // },
          {
            state: 'pationt-profile',
            name: 'ملفات المرضي '
          },
          {
            state: 'settings',
            name: 'بيانات التواصل'
          },
        ]


      },

    ]
  },

  {
    label: 'العيادة',
    main: [
      {
        state: 'clinics',
        short_label: 'm',
        name: 'العيادة',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          // {
          //   state: 'specialites',
          //   name: 'التخصصات'
          // },
          {
            state: 'skills',
            name: 'الجلسات'
          },
          // {
          //   state: 'clinic',
          //   name: 'العيادات'
          // },
          {
            state: 'patients',
            name: ' المرضي',
          },
          {
            state: 'all-reservations',
            name: ' حجز',
          },
          {
            state: 'all-continue-detection',
            name: 'استشارات اليوم',
          },
          // {
          //   state: 'create-prescription',
          //   name: 'كتابة روشتة ',
          // },
          // {
          //   state: 'create-reservation-skill',
          //   name: 'اضافة جلسة الي المريض',
          // },
          {
            state: 'reports',
            name: 'تقارير الحجوزات',
          },
        ]
      },
    ]
  },
  // {
  //   label: 'الحضانات',
  //   main: [
  //     {
  //       state: 'nurseries-unit',
  //       short_label: 'm',
  //       name: 'الحضانات',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
        
  //         {
  //           state: 'all-nurseries',
  //           name: ' كل الحضانات'
  //         },
  //         {
  //           state: 'all-children',
  //           name: ' كل الاطفال'
  //         },
  //         {
  //           state: 'all-cures',
  //           name: 'الادوية'
  //         },
          
  //         {
  //           state: 'create-child',
  //           name: ' اضافة طفل '
  //         },
  //         {
  //           state: 'reservation-nursery',
  //           name: 'حجز حضانة'
  //         },
          
  //         {
  //           state:'all-reservations',
  //           name:'كل الحجوزات'
  //         },
  //         {
  //           state:'today-continues',
  //           name:'متابعات اليوم'

  //         },
  //         {
  //           state:'nersery-tests',
  //           name:' تحاليل الحضانات'

  //         },
  //       ]


  //     },
   
    

  //   ]
  // },
  {
    label: 'الحسابات ',
    main: [
      {
        state: 'accounts',
        short_label: 'm',
        name: 'الحسابات ',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          {
            state: 'revenue-type',
            name: 'انواع الايرادات '
          },
          {
            state: 'expense-type',
            name: 'انواع المصروفات '
          },
          {
            state: 'income',
            name: 'الايرادات'
          },
          {
            state: 'outcome',
            name: 'المصروفات'
          },
          {
            state: 'committed-type',
            name: 'انواع الالتزمات'
          },
          {
            state: 'assets-type',
            name: 'انواع الاصول'
          },
          {
            state: 'committed',
            name: 'الالتزمات'
          },
          {
            state: 'assets',
            name: 'الاصول'
          },
          // {
          //   state: 'students-expenses',
          //   name: 'مصروفات الطلاب'
          // },
          {
            state: 'doctor-accounts',
            name: 'حسابات الاطباء'
          },
          {
            state: 'reports',
            name: 'تقارير الايرادات'
          },
          {
            state: 'outcome-reports',
            name: 'تقارير المصروفات'
          },
          {
            state: 'report-by-department',
            name: ' التقارير'
          },
        ]
      },
    ]
  },
  {
    label: 'الاطباء',
    main: [
      {
        state: 'hr',
        short_label: 'm',
        name: 'الاطباء',
        type: 'sub',
        icon: 'feather icon-user',
        children: [
          {
            state: 'doctor',
            name: 'الاطباء'
          },
          // {
          //   state: 'attendance',
          //   name: 'حضور وانصراف الموظفين'
          // },
          
          // {
          //   state: 'payroll',
          //   name: 'المرتبات'
          // },
          // {
          //   state: 'payroll-manual',
          //   name: ' المرتبات يدويا'
          // }
          // , 
          // {
          //   state: 'vacation-type',
          //   name: 'انواع الاجازات'
          // }
          // ,
         
          // {
          //   state: 'view-vacation',
          //   name: 'الاجازات'
          // }
          // ,
          // {
          //   state: 'result',
          //   name: 'النتائج'
          // },
          // {
          //   state: 'add-reward',
          //   name: 'اضافة مكفأة'
          // },
          // {
          //   state: 'add-deduction',
          //   name: 'اضافة خصم'
          // },
        ]


      },

    ]
  },
  {
    label: 'الشركات',
    main: [
      {
        state: 'companies',
        short_label: 'L',
        name: 'الشركات',
        type: 'link',
        icon: 'feather icon-user'
      },
    ],
  },
  {
    label: 'الموظفين',
    main: [
      {
        state: 'hr',
        short_label: 'm',
        name: 'الموظفين',
        type: 'sub',
        icon: 'feather icon-user',
        children: [
          {
            state: 'employees',
            name: 'الموظفين'
          }
          ,
          // {
          //   state: 'attendance',
          //   name: 'حضور وانصراف الموظفين'
          // },
          
          // {
          //   state: 'payroll',
          //   name: 'المرتبات'
          // },
          // {
          //   state: 'payroll-manual',
          //   name: ' المرتبات يدويا'
          // }
          // , 
          // {
          //   state: 'vacation-type',
          //   name: 'انواع الاجازات'
          // }
          // ,
         
          // {
          //   state: 'view-vacation',
          //   name: 'الاجازات'
          // }
          // ,
          // {
          //   state: 'result',
          //   name: 'النتائج'
          // },
          // {
          //   state: 'add-reward',
          //   name: 'اضافة مكفأة'
          // },
          // {
          //   state: 'add-deduction',
          //   name: 'اضافة خصم'
          // },
        ]


      },

    ]
  },

  // {
  //   label: 'وحدة غسيل الكلي',
  //   main: [
  //     {
  //       state: 'dialysis-unit',
  //       short_label: 'm',
  //       name: 'وحدة غسيل الكلي',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'dialysis-patients',
  //           name: ' المغتسلين',
  //         },
         
  //         {
  //           state:'create-dialysis-data',
  //           name:'بيانات المغتسل'
  //         },
  //         {
  //           state:'uses-type',
  //           name:'الادوية'
  //         },
  //         {
  //           state:'all-daily-uses',
  //           name:'استخدامات المغتسلين'
  //         },
  //         {
  //           state:'report-uses',
  //           name:'استخراج تقرير'
  //         },
          
  //       ]


  //     },

  //   ]
  // },

  // {
  //   label: 'المخازن',
  //   main: [
  //     {
  //       state: 'stocks',
  //       short_label: 'm',
  //       name: 'المخازن',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
        
  //         {
  //           state: 'branches',
  //           name: 'الفروع'
  //         },
  //         {
  //           state: 'categorys',
  //           name: 'التصنيفات'
  //         },
  //         {
  //           state: 'measruing-units',
  //           name: 'وحدات القياس',
  //         },
  //         {
  //           state: 'units',
  //           name: 'الاصناف',
  //         },
  //         {
  //           state: 'dismissal-notice',
  //           name: 'إذن صرف',
  //         },
  //         {
  //           state: 'entrance-permission',
  //           name: 'إذن دخل',
  //         },
  //         {
  //           state:'all-dismissal-notice',
  //           name:'صرف هذا الشهر'
  //         },
        
       
  //       ]


  //     },
   
    

  //   ]
  // },
  // {
  //   label: 'التحليل والاشعة',
  //   main: [
  //     {
  //       state: 'lab',
  //       short_label: 'm',
  //       name: 'التحليل ',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'measruing-units-for-lab',
  //           name: 'وحدات القياس',
  //         },
  //         {
  //           state: 'medicaAnalysis',
  //           name: 'التحليل'
  //         },
  //         {
  //           state: 'create-reservation-ma',
  //           name: 'حجز تحليل'
  //         },
  //         {
  //           state:'today-reservations',
  //           name:'حجوزات اليوم'
  //         },
  //         {
  //           state: 'reservationsLab',
  //           name: ' كل الحجوزات'
  //         },
  //         {
  //           state: 'all-nursery-requests',
  //           name: 'طلبات الحضانات'
  //         },
          
  //         // {
  //         //   state: 'units',
  //         //   name: 'الاصناف',
  //         // },
  //         // {
  //         //   state: 'dismissal-notice',
  //         //   name: 'الاشعة',
  //         // },
  //         // {
  //         //   state: 'reports',
  //         //   name: 'التقارير',
  //         // },
          
       
  //       ]


  //     },
   
    

  //   ]
  // },
  
  // {
  //   label: 'الايتام',
  //   main: [
  //     {
  //       state: 'orphans',
  //       short_label: 'm',
  //       name: 'الايتام ',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'guarantors',
  //           name: 'الكفلاء',
  //         },
  //         {
  //           state: 'orphans-cares',
  //           name: 'الرعايات',
  //         },
  //         {
  //           state: 'mothers',
  //           name: 'العائلات'
  //         },
  //       ]


  //     },
   
    

  //   ]
  // },
  {
    label: 'تسجيل الدخول',
    main: [
      {
        state: 'login',
        short_label: 'L',
        name: 'تسجيل الدخول',
        type: 'link',
        icon: 'feather icon-users'
      },
    ],
  },
]
const LabDoctorMENUITEMS = [
 
  {
    label: 'الرئسية',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  {
    label: 'التحليل والاشعة',
    main: [
      {
        state: 'lab',
        short_label: 'm',
        name: 'التحليل والاشغة',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          {
            state: 'measruing-units-for-lab',
            name: 'وحدات القياس',
          },
          {
            state: 'medicaAnalysis',
            name: 'التحليل'
          },
          {
            state: 'create-reservation-ma',
            name: 'حجز تحليل'
          },
          {
            state:'today-reservations',
            name:'حجوزات اليوم'
          },
          {
            state: 'reservationsLab',
            name: ' كل الحجوزات'
          },
          
          {
            state: 'reports',
            name: 'التقارير',
          },
          
       
        ]


      },
   
    

    ]
  },

];
const EmployeeMENUITEMS = [
  {
    label: 'الرئسية',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },



];
const AccountintMENUITEMS = [
  {
    label: 'الرئسية',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  // {
  //   label: 'البيانات الرئسية',
  //   main: [
  //     {
  //       state: 'master-data',
  //       short_label: 'm',
  //       name: 'البيانات الرئسية',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'departments',
  //           name: 'الإدارات'
  //         },
  //         {
  //           state: 'complex-uses',
  //           name: ' مجمع الاستخدامات '
  //         },
  //       ]


  //     },

  //   ]
  // },
  {
    label: 'الحسابات ',
    main: [
      {
        state: 'accounts',
        short_label: 'm',
        name: 'الحسابات ',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          {
            state: 'revenue-type',
            name: 'انواع الايرادات '
          },
          {
            state: 'expense-type',
            name: 'انواع المصروفات '
          },
          {
            state: 'income',
            name: 'الايرادات'
          },
          {
            state: 'outcome',
            name: 'المصروفات'
          },
          {
            state: 'committed-type',
            name: 'انواع الالتزمات'
          },
          {
            state: 'assets-type',
            name: 'انواع الاصول'
          },
          {
            state: 'committed',
            name: 'الالتزمات'
          },
          {
            state: 'assets',
            name: 'الاصول'
          },
          {
            state: 'students-expenses',
            name: 'مصروفات الطلاب'
          },
          // {
          //   state: 'doctor-accounts',
          //   name: 'حسابات الاطباء'
          // },
          {
            state: 'reports',
            name: 'تقارير الايرادات'
          },
          {
            state: 'outcome-reports',
            name: 'تقارير المصروفات'
          },
        ]
      },
    ]
  },
  // {
  //   label: 'الموارد البشرية',
  //   main: [
  //     {
  //       state: 'hr',
  //       short_label: 'm',
  //       name: 'الموارد البشرية',
  //       type: 'sub',
  //       icon: 'feather icon-user',
  //       children: [
  //         {
  //           state: 'doctor',
  //           name: 'الاطباء'
  //         },
  //         {
  //           state: 'employees',
  //           name: 'الموظفين'
  //         }
  //         , 
  //         {
  //           state: 'vacation-type',
  //           name: 'انواع الاجازات'
  //         }
  //         ,
         
  //         {
  //           state: 'view-vacation',
  //           name: 'الاجازات'
  //         }
  //         ,
  //         {
  //           state: 'result',
  //           name: 'النتائج'
  //         },
  //         {
  //           state: 'add-reward',
  //           name: 'اضافة مكفأة'
  //         },
  //         {
  //           state: 'add-deduction',
  //           name: 'اضافة خصم'
  //         },

          

          
  //       ]


  //     },

  //   ]
  // },
  
  // {
  //   label: 'الايتام',
  //   main: [
  //     {
  //       state: 'orphans',
  //       short_label: 'm',
  //       name: 'الايتام ',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'guarantors',
  //           name: 'الكفلاء',
  //         },
  //         {
  //           state: 'mothers',
  //           name: 'العائلات'
  //         },
  //       ]


  //     },
   
    

  //   ]
  // }
];

const DoctorMENUITEMS = [
 
  // {
  //   label: 'الرئسية',
  //   main: [
  //     {
  //       state: 'dashboard',
  //       short_label: 'D',
  //       name: 'الرئسية',
  //       type: 'link',
  //       icon: 'feather icon-home'
  //     }
  //   ]
  // },
  
  {
    label: 'العيادة',
    main: [
      {
        state: 'clinics',
        short_label: 'm',
        name: 'العيادة',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          {
            state: 'create-prescription',
            name: 'كتابة روشتة ',
          },
          {
            state: 'create-reservation-skill',
            name: 'اضافة جلسة الي المريض',
          },
        ]
      },
    ]
  },
  // {
  //   label: 'وحدة غسيل الكلي',
  //   main: [
  //     {
  //       state: 'dialysis-unit',
  //       short_label: 'm',
  //       name: 'وحدة غسيل الكلي',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'dialysis-patients',
  //           name: ' المغتسلين',
  //         },
         
  //         {
  //           state:'create-dialysis-data',
  //           name:'بيانات المغتسل'
  //         },
  //         {
  //           state:'uses-type',
  //           name:'الادوية'
  //         },
  //         {
  //           state:'all-daily-uses',
  //           name:'استخراج تقرير'
  //         },
  //       ]


  //     },

  //   ]
  // },
  // {
  //   label: 'الحضانات',
  //   main: [
  //     {
  //       state: 'nurseries-unit',
  //       short_label: 'm',
  //       name: 'الحضانات',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
        
  //         {
  //           state: 'all-nurseries',
  //           name: ' كل الحضانات'
  //         },
  //         {
  //           state: 'all-children',
  //           name: ' كل الاطفال'
  //         },
  //         {
  //           state: 'all-cures',
  //           name: 'الادوية'
  //         },
          
  //         {
  //           state: 'create-child',
  //           name: ' اضافة طفل '
  //         },
  //         {
  //           state: 'reservation-nursery',
  //           name: 'حجز حضانة'
  //         },
          
  //         {
  //           state:'all-reservations',
  //           name:'كل الحجوزات'
  //         },
  //         {
  //           state:'today-continues',
  //           name:'متابعات اليوم'

  //         },
       
  //       ]


  //     },
   
    

  //   ]
  // },
  // {
  //   label: 'التحليل والاشعة',
  //   main: [
  //     {
  //       state: 'lab',
  //       short_label: 'm',
  //       name: 'التحليل والاشغة',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'measruing-units-for-lab',
  //           name: 'وحدات القياس',
  //         },
  //         {
  //           state: 'medicaAnalysis',
  //           name: 'التحليل'
  //         },
  //         {
  //           state: 'create-reservation-ma',
  //           name: 'حجز تحليل'
  //         },
  //         {
  //           state:'today-reservations',
  //           name:'حجوزات اليوم'
  //         },
  //         {
  //           state: 'reservationsLab',
  //           name: ' كل الحجوزات'
  //         },
          
  //         // {
  //         //   state: 'units',
  //         //   name: 'الاصناف',
  //         // },
  //         // {
  //         //   state: 'dismissal-notice',
  //         //   name: 'الاشعة',
  //         // },
  //         {
  //           state: 'reports',
  //           name: 'التقارير',
  //         },
          
       
  //       ]


  //     },
   
    

  //   ]
  // },
  // {
  //   label: 'الحضانات',
  //   main: [
  //     {
  //       state: 'nurseries-unit',
  //       short_label: 'm',
  //       name: 'الحضانات',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
        
  //         {
  //           state: 'all-nurseries',
  //           name: ' كل الحضانات'
  //         },
  //         {
  //           state: 'all-children',
  //           name: ' كل الاطفال'
  //         },
  //         {
  //           state: 'all-cures',
  //           name: 'الادوية'
  //         },
          
  //         {
  //           state: 'create-child',
  //           name: ' اضافة طفل '
  //         },
  //         {
  //           state: 'reservation-nursery',
  //           name: 'حجز حضانة'
  //         },
          
  //         {
  //           state:'all-reservations',
  //           name:'كل الحجوزات'
  //         },
  //         {
  //           state:'today-continues',
  //           name:'متابعات اليوم'

  //         },
  //         {
  //           state:'nersery-tests',
  //           name:' تحاليل الحضانات'

  //         },
  //       ]


  //     },
   
    

  //   ]
  // },
  
];
const StocksAdminMENUITEMS = [
 
  {
    label: 'الرئسية',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  // {
  //   label: 'المخازن',
  //   main: [
  //     {
  //       state: 'stocks',
  //       short_label: 'm',
  //       name: 'المخازن',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
        
  //         {
  //           state: 'branches',
  //           name: 'الفروع'
  //         },
  //         {
  //           state: 'categorys',
  //           name: 'التصنيفات'
  //         },
  //         {
  //           state: 'measruing-units',
  //           name: 'وحدات القياس',
  //         },
  //         {
  //           state: 'units',
  //           name: 'الاصناف',
  //         },
  //         {
  //           state: 'dismissal-notice',
  //           name: 'إذن صرف',
  //         },
  //         {
  //           state: 'entrance-permission',
  //           name: 'إذن دخل',
  //         },
          
        
       
  //       ]


  //     },
   
    

  //   ]
  // },


];
const HRAdminMENUITEMS = [
 
  {
    label: 'الرئسية',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  
  {
    label: 'الموارد البشرية',
    main: [
      {
        state: 'hr',
        short_label: 'm',
        name: 'الموارد البشرية',
        type: 'sub',
        icon: 'feather icon-user',
        children: [
          {
            state: 'doctor',
            name: 'الاطباء'
          },
          {
            state: 'employees',
            name: 'الموظفين'
          }
          , 
          // {
          //   state: 'vacation-type',
          //   name: 'انواع الاجازات'
          // }
          // ,
         
          // {
          //   state: 'view-vacation',
          //   name: 'الاجازات'
          // }
          // ,
          // {
          //   state: 'result',
          //   name: 'النتائج'
          // },
          // {
          //   state: 'add-reward',
          //   name: 'اضافة مكفأة'
          // },
          // {
          //   state: 'add-deduction',
          //   name: 'اضافة خصم'
          // },

          

          
        ]


      },

    ]
  },


];
const NurceMENUITEMS = [
 
  {
    label: 'الرئسية',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  {
    label: 'البيانات الرئسية',
    main: [
      {
        state: 'master-data',
        short_label: 'm',
        name: 'البيانات الرئسية',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
        
        
          // {
          //   state: 'complex-uses',
          //   name: ' مجمع الاستخدامات '
          // },
          // {
          //   state: 'pationt-profile',
          //   name: 'ملفات المرضي '
          // },
          
        ]


      },

    ]
  },

  // {
  //   label: 'Nurseries',
  //   main: [
  //     {
  //       state: 'nurseries-unit-for-doctor',
  //       short_label: 'm',
  //       name: 'Nurseries',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
        
  //         {
  //           state: 'all-children',
  //           name: ' كل الاطفال'
  //         },
       
          
  //         {
  //           state:'all-reservations',
  //           name:'كل الحجوزات'
  //         },
        
       
  //       ]


  //     },
   
    

  //   ]
  // },
 


];
const ReceptionestMENUITEMS = [
 
  {
    label: 'الرئسية',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  
  {
    label: 'البيانات الرئسية',
    main: [
      {
        state: 'master-data',
        short_label: 'm',
        name: 'البيانات الرئسية',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          // {
          //   state: 'departments',
          //   name: 'الإدارات'
          // },
          // {
          //   state: 'complex-uses',
          //   name: ' مجمع الاستخدامات '
          // },
          {
            state: 'pationt-profile',
            name: 'ملفات المرضي '
          },
          {
            state: 'settings',
            name: 'بيانات التواصل'
          },
        ]


      },

    ]
  },

  {
    label: 'العيادة',
    main: [
      {
        state: 'clinics',
        short_label: 'm',
        name: 'العيادة',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          {
            state: 'skills',
            name: 'الجلسات'
          },
          {
            state: 'patients',
            name: ' المرضي',
          },
          {
            state: 'all-reservations',
            name: ' حجز',
          },
          {
            state: 'all-continue-detection',
            name: 'استشارات اليوم',
          },
          {
            state: 'reports',
            name: 'تقارير الحجوزات',
          },
        ]
      },
    ]
  },

  // {
  //   label: 'وحدة غسيل الكلي',
  //   main: [
  //     {
  //       state: 'dialysis-unit',
  //       short_label: 'm',
  //       name: 'وحدة غسيل الكلي',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'dialysis-patients',
  //           name: ' المغتسلين',
  //         },
         
  //         {
  //           state:'create-dialysis-data',
  //           name:'بيانات المغتسل'
  //         },
         
  //         {
  //           state:'all-daily-uses',
  //           name:'استخراج تقرير'
  //         },
  //       ]


  //     },

  //   ]
  // },

];
const LabReceptionestMENUITEMS = [
 
  {
    label: 'الرئسية',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  {
    label: 'المرضي',
    main: [
      {
        state: 'clinics',
        short_label: 'm',
        name: 'المرضي',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          {
            state: 'patients',
            name: ' المرضي',
          },
        ]
      },
    ]
  },
  // {
  //   label: 'التحليل والاشعة',
  //   main: [
  //     {
  //       state: 'lab',
  //       short_label: 'm',
  //       name: 'التحليل والاشغة',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
          
  //         {
  //           state: 'create-reservation-ma',
  //           name: 'حجز تحليل'
  //         },
  //         {
  //           state: 'reservationsLab',
  //           name: ' كل الحجوزات'
  //         },
          
  //         {
  //           state: 'reports',
  //           name: 'التقارير',
  //         },
          
       
  //       ]


  //     },
   
    

  //   ]
  // },
  // {
  //   label: 'وحدة غسيل الكلي',
  //   main: [
  //     {
  //       state: 'dialysis-unit',
  //       short_label: 'm',
  //       name: 'وحدة غسيل الكلي',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'dialysis-patients',
  //           name: ' المغتسلين',
  //         },
         
  //         {
  //           state:'create-dialysis-data',
  //           name:'بيانات المغتسل'
  //         },
         
  //         {
  //           state:'all-daily-uses',
  //           name:'استخراج تقرير'
  //         },
  //       ]


  //     },

  //   ]
  // },

];
const NurseryReceptionestMENUITEMS = [
 
  {
    label: 'Home',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Home',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  
  {
    label: ' Nurseries',
    main: [
      {
        state: 'nurseries-unit',
        short_label: 'm',
        name: ' Nurseries',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          // {
          //   state: 'all-nurseries',
          //   name: ' All Nurseries'
          // },
          // {
          //   state: 'all-children',
          //   name: 'All Children'
          // },
          // {
          //   state: 'create-child',
          //   name: 'Add Child'
          // },
          // {
          //   state: 'reservation-nursery',
          //   name: 'Reservation Nursery'
          // },
          // {
          //   state:'all-reservations',
          //   name:'All Reservations'
          // },
        ]


      },
   
    

    ]
  },
 

];
const NurseryDoctorMENUITEMS = [
  {
    label: 'Home',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Home',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  
  {
    label: 'Nurseries',
    main: [
      {
        state: 'nurseries-unit-for-doctor',
        short_label: 'm',
        name: 'Nurseries',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          // {
          //   state: 'all-children',
          //   name: ' All Children'
          // },
         
          
          // {
          //   state: 'create-child',
          //   name: 'Add Child '
          // },
          // {
          //   state: 'reservation-nursery',
          //   name: 'Reservation Nursery'
          // },
          
          // {
          //   state:'all-reservations',
          //   name:'All Reservations'
          // },
        
       
        ]


      },
   
    

    ]
  },
 
];
const DialisesUnitReceptionestMENUITEMS = [
 
  {
    label: 'الرئسية',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  
  {
    label: 'البيانات الرئسية',
    main: [
      {
        state: 'master-data',
        short_label: 'm',
        name: 'البيانات الرئسية',
        type: 'sub',
        icon: 'feather icon-edit-1',
        children: [
          {
            state: 'complex-uses',
            name: ' مجمع الاستخدامات '
          },
          {
            state:'uses-type',
            name:'الادوية'
          },
          {
            state: 'pationt-profile',
            name: 'ملفات المرضي '
          },
        ]
      },
    ]
  },
  // {
  //   label: 'وحدة غسيل الكلي',
  //   main: [
  //     {
  //       state: 'dialysis-unit',
  //       short_label: 'm',
  //       name: 'وحدة غسيل الكلي',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'dialysis-patients',
  //           name: ' المغتسلين',
  //         },
         
  //         {
  //           state:'create-dialysis-data',
  //           name:'بيانات المغتسل'
  //         },
         
  //         {
  //           state:'all-daily-uses',
  //           name:'استخراج تقرير'
  //         },
  //       ]


  //     },

  //   ]
  // },
];
const AdminBenefactorMENUITEMS = [
 
  {
    label: 'الرئسية',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'الرئسية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  // {
  //   label: 'الايتام',
  //   main: [
  //     {
  //       state: 'orphans',
  //       short_label: 'm',
  //       name: 'الايتام ',
  //       type: 'sub',
  //       icon: 'feather icon-edit-1',
  //       children: [
  //         {
  //           state: 'guarantors',
  //           name: 'الكفلاء',
  //         },
  //         {
  //           state: 'orphans-cares',
  //           name: 'الرعايات',
  //         },
          
  //         {
  //           state: 'mothers',
  //           name: 'العائلات'
  //         },
  //       ]


  //     },
   
    

  //   ]
  // },
];
const PharmaceuticalCompanyUserMENUITEMS = [
 
  // {
  //   label: 'الرئسية',
  //   main: [
  //     {
  //       state: 'dashboard',
  //       short_label: 'D',
  //       name: 'الرئسية',
  //       type: 'link',
  //       icon: 'feather icon-home'
  //     }
  //   ]
  // },
  {
    label: 'الادوية',
    main: [
      {
        state: 'pharmaceutical',
        short_label: 'D',
        name: 'الادوية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  
];
const PharmaceuticalCompanyAdminMENUITEMS = [
 
  // {
  //   label: 'الرئسية',
  //   main: [
  //     {
  //       state: 'dashboard',
  //       short_label: 'D',
  //       name: 'الرئسية',
  //       type: 'link',
  //       icon: 'feather icon-home'
  //     }
  //   ]
  // },
  {
    label: 'الادوية',
    main: [
      {
        state: 'users-for-admin',
        short_label: 'D',
        name: 'المستخدمين',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  {
    label: 'الادوية',
    main: [
      {
        state: 'pharmaceutical',
        short_label: 'D',
        name: 'الادوية',
        type: 'link',
        icon: 'feather icon-home'
      }
    ]
  },
  
];
@Injectable()
export class MenuItems {
  // if (canActivate:[AdminGuard]) {
  // }
  getAll(): Menu[] {
    return MENUITEMS;
  }

  getAllForEmployee(): Menu[] {
    return   EmployeeMENUITEMS   ;
  }
  getAllForLabReceptionest(): Menu[] {
    return   LabReceptionestMENUITEMS   ;
  }
  getAllForNurseryReceptionest(): Menu[] {
    return   NurseryReceptionestMENUITEMS   ;
  }
  getAllForNurseryDoctor(): Menu[] {
 debugger;
    return   NurseryDoctorMENUITEMS   ;
  }
  getAllForAccountint(): Menu[] {
    return AccountintMENUITEMS;
  }
  getAllForDoctor(): Menu[] {
    return DoctorMENUITEMS;
  }
  getAllForStocksAdmin(): Menu[] {
    return StocksAdminMENUITEMS;
  }
  getAllForHRAdmin(): Menu[] {
    return HRAdminMENUITEMS;
  }
  getAllForNurce(): Menu[] {
    return NurceMENUITEMS;
  }
  getAllForReceptionest(): Menu[] {
    return ReceptionestMENUITEMS;
  }
  getAllForLabDoctor(): Menu[] {
    return LabDoctorMENUITEMS;
  }
  getAllForDialisesUnitReceptionest(): Menu[] {
    return DialisesUnitReceptionestMENUITEMS;
  }
  getAllAdminBenefactor(): Menu[] {
    return AdminBenefactorMENUITEMS;
  }
  getAllPharmaceuticalCompanyUser(): Menu[] {
    return PharmaceuticalCompanyUserMENUITEMS;
  }
  getAllPharmaceuticalCompanyAdmin(): Menu[] {
    return PharmaceuticalCompanyAdminMENUITEMS;
  }
}
