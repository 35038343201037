// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
DevApiUrl: 'http://apis.el-roby.com/api'
  // DevApiUrl: 'http://ahmedsayedahmed-001-site5.ftempurl.com/api'
//  DevApiUrl: 'http://192.168.1.100:8082/api'

};

// export const environment = {
//   production: true,
//   firebase: {
//     apiKey: "...",
//     authDomain: "http://192.168.1.8:8080/api",
//     databaseURL: "http://192.168.1.8:8080/api",
//     projectId: "project",
//    storageBucket: "http://192.168.1.8:8080/api",
//     messagingSenderId: "..."
//   },
//   hmr: false,
//   //production: false,
//   DevApiUrl: 'http://192.168.1.8:8080/api'
// }

